import { Component, Watch } from '@zento-lib/components';

import { Menu } from '../../Menu';
import { MenuList } from '../../../../molecule/Menu/List';
import { HeaderItem } from '../../../../atom/HeaderItem/HeaderItem';
import { Overlay } from '../../../../atom/Overlay/Overlay';

import style from './style.scss';

@Component({})
export class Menu2 extends Menu {
  @Watch('isDesktop')
  getIsDesktop() {
    if (this.isDesktop) {
      // Reset menu to first level
      this.submenu.path = [];
      this.$store.commit('ui/setSidebar', false);
      document.body.style.overflow = 'visible';
    } else if (this.isOpened && !this.isDesktop) {
      document.body.style.overflow = 'hidden';
    }
  }

  data() {
    return {
      showOverlay: false,
    };
  }

  beforeMount() {
    this.disableHover = this.disableHover.bind(this);
    this.enableHover = this.enableHover.bind(this);
  }

  render() {
    return (
      <div class={[style.menuWrapper, style.hoverable]}>
        <HeaderItem
          label={{ id: Menu.T.labelHamburger }}
          icon={this.icon}
          onPress={this.openSidebarMenu}
          class={style.hamburgerIcon}
          testId='hamburger-icon'
          key='hamburger-icon'
        />

        {this.isOpened && !this.isDesktop && this.$data.showOverlay ? (
          <div onClick={this.closeMenu} class={style.showOverlay} key='main-menu-overlay'>
            <Overlay />
          </div>
        ) : null}

        {this.isOpened && !this.isDesktop ? (
          <div
            onClick={this.closeMenu}
            aria-label={this.getTranslation({ id: Menu.T.closeIcon })}
            class={style.closeBtn}
            key='close-btn'
          />
        ) : null}

        <transition
          name='slide-in-left'
          enterActiveClass={!this.isDesktop ? style.slideInLeftEnterActive : ''}
          leaveActiveClass={!this.isDesktop ? style.slideInLeftLeaveActive : ''}
          key='main-menu-wrapper'>
          <div
            v-show={this.isOpened}
            class={{ [style.mainMenu]: true, [style.mainMenuOpened]: this.isOpened }}
            onClick_capture={this.disableHover}
            onMouseEnter_capture={this.enableHover}>
            <div class={style.leftSidebar}>
              {!this.isDesktop && !this.selectedCatId ? (
                <div class={style.menuSidebarHeader}>
                  <strong>{this.tree.name}</strong>
                </div>
              ) : null}
              <div
                onClick={this.stopPropagation}
                id='main-menu-container'
                class={style.mainMenuContainer}
                key='main-menu-list'>
                <MenuList
                  items={this.items}
                  depth={this.depth}
                  promotedItemsLimit={this.extended.$config.zento.theme?.menu?.promotedItemsLimit}
                  key='items-list'
                />
              </div>
            </div>
          </div>
        </transition>
      </div>
    );
  }

  /**
   * Open categories sidebar
   */
  protected openSidebarMenu() {
    this.$store.commit('ui/setSidebar', !this.isOpened);
    this.$store.commit('ui/setOverlay', false);
    this.$data.showOverlay = !this.isOpened;
  }

  /**
   * Close main menu sidebar
   */
  protected closeMenu() {
    this.$store.commit('ui/setSidebar', false);
    this.$store.commit('ui/setOverlay', false);
    this.$data.showOverlay = false;

    // Reset menu to first level
    this.submenu.path = [];

    if (!this.isDesktop || this.desktopDrawer) {
      document.body.style.overflow = 'visible';
    }
  }

  /**
   * Disables menu hover support while navigation to another page
   */
  private disableHover() {
    this.$el.classList.remove(style.hoverable);

    if (this.isDesktop) {
      this.$store.commit('ui/setOverlay', false);
    }
  }

  /**
   * Enable menu hover support after navigation to another page if the user leaves the menu area
   */
  private enableHover() {
    if (!this.$el.classList.contains(style.hoverable)) {
      this.$el.classList.add(style.hoverable);
    }
  }
}
